<template>
  <v-layout column style="width: 100%; height: 100%; overflow-y: hidden">
    <v-container style="max-width: 1640px; height: 100%; overflow: hidden; position: relative">
      <v-layout align-center class="fill-height">
        <div style="height: 85vh; width: 100%; background: var(--v-bgContainer-base); border-radius: 13px;">
          <v-layout class="fill-height overflow-y-hidden px-5" column>
            <div
              style="flex: none; height: 88px; width: 100%; text-align: center; border-bottom: 1px solid rgba(93, 101, 136, 0.5)"
            >
              <v-layout align-center class="fill-height px-5">
                <h3 class="text-uppercase">Admin side </h3>
                <v-spacer />
              </v-layout>
            </div>
            <v-row no-gutters>
              <v-col v-for="item in menus" :key="item.name" class="pa-4" cols="12" lg="3" md="4" sm="6" xl="3" xs="12">
                <div class="admin-menu-card d-flex flex-column justify-center align-center" @click="goToLink(item)">
                  <v-icon size="100">{{ item.icon }}</v-icon>
                  <div class="mt-2">{{ item.name }}</div>
                </div>
              </v-col>
              <v-col class="pa-4" cols="12" lg="3" md="4" sm="6" xl="3" xs="12">
                <div
                  class="admin-menu-card d-flex flex-column justify-center align-center"
                  @click="() => ($refs.upload.dialog = true)"
                >
                  <v-icon size="100">mdi-cloud-upload</v-icon>
                  <div class="mt-2">Upload data</div>
                </div>
              </v-col>


             
            </v-row>
          </v-layout>
        </div>
      </v-layout>
    </v-container>
    <Upload ref="upload" />
  </v-layout>
</template>

<script>
import Upload from '@/views/admin-side/upload/Upload.vue'

export default {
  name: 'Project',
  components: { Upload },
  data() {
    return {
      menus: [
        { name: 'Users', icon: 'icon-users_group', link: '/admin/users' },
        { name: 'Organizations', icon: 'mdi-home-modern', link: '/admin/organizations' },
        { name: 'Orders', icon: 'icon-edit_square', link: '/admin/orders' },
        // { name: 'Logs debug', icon: 'icon-debug', link: '/admin/log-debug', newTab: false },
        { name: 'Logs debug', icon: 'icon-debug', link: process.env.VUE_APP_LOGVIEW, newTab: true },
        {
          name: 'System monitoring',
          icon: 'mdi-monitor',
          link: process.env.VUE_APP_SYSTEM_MONITORING,
          newTab: true,
        },
        {
          name:'CMS Module',icon:'mdi-file-document-edit',link:'/admin/cms'
        }
      ],
    }
  },

  mounted() {},
  methods: {
    goToLink(item) {
      if (item.newTab) {
        window.open(item.link, '_blank')
      } else {
        this.$router.push(item.link)
      }
    },
  },
}
</script>

<style scoped>
.admin-menu-card {
  height: 200px;
  background: #2f324180;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);
  width: 100%;
}

.admin-menu-card:hover {
  box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.16);
  transform: scale3d(1.05, 1.05, 1.05);
  color: white;
  font-weight: bold;
}
</style>
